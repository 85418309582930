import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { formatNumber, getGrandTotal } from '@hempitecture/utils';
import type { FormValues } from '@hempitecture/types';

import { useOrder, useShipment } from '@/context';
import { cn } from '@/utils/helper';
import styles from './OrderSummary.module.scss';

function formatDate(isoString: string): string {
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString(undefined, options);
}

/**
 * The `OrderSummary` converts all order and shipping data into a
 * checkout summary. This is displayed while a user is prompted for credit
 * card or ACH payment.
 */
export const OrderSummary = () => {
  const { values }: { values: FormValues } = useFormikContext();
  const { order, paymentType, getOrderSummary } = useOrder();
  const { shipping, freeShipping } = useShipment();
  const items = getOrderSummary();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    const el = document.getElementById('col-form');
    el.scrollTop = 0;
  }, []);

  const taxRate =
    order.fulfillmentType === 'Delivery'
      ? order.taxRates[values.state]
      : order.taxRates[order.selectedPickupLocation.stateCode];

  const {
    total_formatted,
    tax_formatted,
    fee_formatted,
    discount_formatted,
    shippingTotal_formatted,
    productTotal,
  } = getGrandTotal({
    inventory: order.inventory,
    products: values.products,
    shipping: shipping.shippingRate,
    taxRate,
    processingFee: paymentType === 'Credit Card',
    freeShipping,
    discount: order.partner?.discount,
  });

  // 30% of the material and shipping or $1200 whichever is lower.
  const taxCredit = formatNumber(
    Math.min(Math.round(productTotal * 0.3), 1200)
  );

  const isFTL = order.palletCount > 12;

  const sectionTitle =
    order.fulfillmentType === 'Delivery'
      ? 'Contact & Shipping Details'
      : 'Contact Information';

  return (
    <div className={styles.root} data-cy="order-summary">
      <h2>Order Summary</h2>
      {items.map(({ name, pallets, price }) => (
        <div key={name} className={cn(styles.item, 'flex justify-between')}>
          <div>
            <div>{name}</div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              {pallets} {pallets > 1 ? 'pallets' : 'pallet'}
            </div>
          </div>
          <div>
            <strong>${formatNumber(price)}</strong>
          </div>
        </div>
      ))}
      {order?.partner?.discount ? (
        <div className={styles.discount}>
          <div>Partner Discount ({order.partner.discount * 100}% Off)</div>
          <div>
            <strong>-${discount_formatted}</strong>
          </div>
        </div>
      ) : null}
      {order.fulfillmentType === 'Delivery' ? (
        <>
          <div data-cy="shipping-total" className={styles.total}>
            <div>Shipping</div>
            <div>${shippingTotal_formatted}</div>
          </div>
          {freeShipping ? (
            <div
              data-cy="shipping-total"
              className={cn(styles.total, styles.shipping)}>
              <div>FREE Shipping</div>
              <div>-${shippingTotal_formatted}</div>
            </div>
          ) : null}
        </>
      ) : null}
      <div className={'flex justify-between bold'}>
        <div className="caps">Tax</div>
        <div>${tax_formatted}</div>
      </div>
      {order.paymentType === 'Credit Card' ? (
        <div data-cy="cc-fee" className={'flex justify-between bold'}>
          <div className="caps">Credit Card Fee</div>
          <div>${fee_formatted}</div>
        </div>
      ) : null}
      <div className={cn(styles.tax_credit, 'flex justify-between align-end')}>
        <div className="flex-1">
          <div className="bold">25C Home Energy Tax Credit</div>
          <a
            href="https://www.hempitecture.com/post/unlocking-the-25c-tax-credit-your-guide-to-energy-efficient-home-upgrades-and-savings/"
            rel="noopener noreferrer"
            target="_blank">
            See details
          </a>
        </div>
        <div>-${taxCredit}</div>
      </div>
      <div
        className="flex justify-between bold"
        style={{ margin: '30px 0 40px', fontSize: 20 }}>
        <div className="caps">Total</div>
        <div>${total_formatted}</div>
      </div>
      <div className={styles.summary}>
        <h3>{sectionTitle}</h3>
        <div style={{ padding: '0 25px 20px' }}>
          <div>{values.name}</div>
          <div>{values.email}</div>
          <div className={styles.line}>{values.phone}</div>
          <div className={styles.line}>
            <strong>Fulfillment Type: </strong>
            {order.fulfillmentType === 'Pickup' ? 'Pickup' : 'Delivery'}
          </div>
          {order.fulfillmentType === 'Pickup' && (
            <div className={styles.line}>
              <strong>Pickup Date: </strong>
              {formatDate(values.pickupDate)}
            </div>
          )}
          {order.fulfillmentType === 'Delivery' ? (
            <div data-cy="summary-delivery">
              <div style={{ marginBottom: 30 }}>
                {values.address1}
                <br />
                {values.address2 ? (
                  <>
                    {values.address2} <br />
                  </>
                ) : null}
                {values.city}, {values.state} {values.zip}
              </div>
              {!isFTL ? (
                <div>
                  <strong>Liftgate:</strong> {values.liftgate ? 'Yes' : 'No'}
                </div>
              ) : null}
              <div>
                <strong>Residential:</strong>{' '}
                {values.residential ? 'Yes' : 'No'}
              </div>
              <div>
                <strong>Delivery Appointment:</strong>{' '}
                {values.appointment ? 'Yes' : 'No'}
              </div>
              <div>
                <strong>Inside Delivery:</strong>{' '}
                {values.insideDelivery ? 'Yes' : 'No'}
              </div>
              <div>
                <strong>Shipping Warehouse:</strong>{' '}
                {shipping.closestDistributor.cityAlias},{' '}
                {shipping.closestDistributor.stateCode}
              </div>
              <div style={{ paddingBottom: 20 }}>
                <strong>Contact Phone:</strong> {values.phone}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {order.fulfillmentType === 'Pickup' ? (
        <div className={styles.summary} data-cy="summary-pickup">
          <h3>Pickup Location</h3>
          <div style={{ padding: '0 25px 30px' }}>
            <div>
              <strong>{order.selectedPickupLocation.name}</strong>
            </div>
            <div>{order.selectedPickupLocation.line1}</div>
            {order.selectedPickupLocation.line2 ? (
              <div>{order.selectedPickupLocation.line2}</div>
            ) : null}
            <div>
              {order.selectedPickupLocation.cityName},{' '}
              {order.selectedPickupLocation.stateCode},{' '}
              {order.selectedPickupLocation.postalCode}
            </div>
            <div>
              {order.selectedPickupLocation.countryCode === 'US'
                ? 'USA'
                : 'Canada'}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
