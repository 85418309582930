import { Inventory } from '@hempitecture/types';
import styles from './PalletPricing.module.scss';

interface PalletPricingProps {
  inventory?: Inventory;
  type?: 'Hempcrete' | 'HempWool' | 'Blocks';
  close?: () => void;
}

export const PalletPricing = ({
  inventory,
  type,
  close,
}: PalletPricingProps) => {
  const skus = Object.keys(inventory);
  const isHempwool = type === 'HempWool';

  return inventory ? (
    <>
      <button
        type="button"
        className={styles.close}
        onClick={() => close && close()}>
        Close
      </button>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>SKU</th>
              <th>{isHempwool ? 'Sqft / Pallet' : 'Lbs / Pallet'}</th>
              <th>Price / Pallet</th>
            </tr>
          </thead>
          <tbody>
            {skus.map((sku) => {
              const {
                name,
                palletWeight,
                palletSize,
                palletPrice,
                productType,
              } = inventory[sku];
              const show =
                (type === 'HempWool' && productType === type) ||
                (type !== 'HempWool' && productType !== 'HempWool');
              return show ? (
                <tr key={sku}>
                  <td>{name}</td>
                  <td>{sku}</td>
                  <td>{isHempwool ? palletSize : palletWeight}</td>
                  <td>{formatAsUSD(palletPrice)}</td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </div>
    </>
  ) : null;
};

function formatAsUSD(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}
