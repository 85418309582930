import { cn } from '@/utils/helper';
import { HempWoolLogo } from '@/components/shared/Logos';
import styles from './MaintenanceAlert.module.scss';

export const MaintenanceAlert = () => {
  return (
    <div
      className={cn(
        styles.root,
        'flex align-center justify-center fixed cover'
      )}>
      <div>
        <HempWoolLogo />
        <p>{`We're currently upgrading our Buy Direct experience.`}</p>
        <p>
          Please contact{' '}
          <a href="mailto:sales@hempitecture.com">sales@hempitecture.com</a> for
          a direct quote.
        </p>
      </div>
    </div>
  );
};
