import { InfoPopup } from '../../alerts/InfoPopup';

export const fields = [
  {
    name: 'name',
    id: 'name',
    label: 'Name',
    placeholder: 'Name for shipping*',
    type: 'text',
    error: 'top',
    required: true,
  },
  {
    name: 'email',
    id: 'email',
    label: 'Email Address',
    placeholder: 'Email address*',
    type: 'email',
    error: 'top',
    required: true,
  },
  {
    name: 'audienceType',
    id: 'audienceType',
    label: 'Audience Type',
    type: 'select',
    error: 'top',
  },
  {
    name: 'address1',
    id: 'address1',
    label: 'Address Line 1',
    placeholder: 'Street Address*',
    type: 'text',
    error: 'top',
    required: true,
  },
  {
    name: 'address2',
    id: 'address2',
    label: 'Address Line 2',
    placeholder: 'Apartment, building, or unit number',
    type: 'text',
    error: 'top',
  },
  {
    name: 'city',
    id: 'city',
    label: 'City',
    placeholder: 'City or Town*',
    type: 'text',
    error: 'top',
    required: true,
  },
  {
    name: 'state',
    id: 'state',
    label: 'State',
    placeholder: 'State*',
    type: 'select',
    col: true,
    error: 'bottom',
    required: true,
  },
  {
    name: 'zip',
    id: 'zip',
    label: 'Postal Code',
    placeholder: 'Postal Code*',
    type: 'text',
    col: true,
    error: 'bottom',
    required: true,
  },
  {
    name: 'country',
    id: 'country',
    label: 'Country',
    placeholder: 'Country*',
    type: 'select',
    error: 'bottom',
    required: true,
  },
  {
    name: 'phone',
    id: 'phone',
    label: 'Contact Phone Number',
    placeholder: 'Phone Number*',
    type: 'text',
    error: 'top',
    required: true,
  },
  {
    name: 'liftgate',
    id: 'liftgate',
    label: (
      <div className="flex align-center">
        Liftgate Required?
        <InfoPopup>
          A liftgate is needed to load/unload heavy items from a truck. You will
          need a liftgate unless you have a forklift on site. You{' '}
          <strong>cannot</strong> manually remove these pallets by hand. If you
          do not select a liftgate delivery and you do not have a forklift, you
          will be charged for reshipping the material with a liftgate.
        </InfoPopup>
      </div>
    ),
    placeholder: '',
    type: 'switch',
  },
  {
    name: 'residential',
    id: 'residential',
    label: (
      <div className="flex align-center">
        Residential Delivery Address?
        <InfoPopup>
          Select this option if HempWool will be delivered to a residential
          address
        </InfoPopup>
      </div>
    ),
    placeholder: '',
    type: 'switch',
  },
  {
    name: 'insideDelivery',
    id: 'insideDelivery',
    label: (
      <div className="flex align-center">
        Inside Delivery
        <InfoPopup>
          A limited access location is{' '}
          <strong>
            any destination that restricts trucks from entering an area
          </strong>
          . In other words, this means the driver will require more time and
          work to load, unload, and deliver the shipment
        </InfoPopup>
      </div>
    ),
    placeholder: '',
    type: 'switch',
  },
  {
    name: 'appointment',
    id: 'appointment',
    label: (
      <div className="flex align-center">
        Delivery Appointment Required?
        <InfoPopup>
          Select this option if you would like the shipping company to call you
          before delivery, for an added fee.
        </InfoPopup>
      </div>
    ),
    placeholder: '',
    type: 'switch',
  },
  {
    name: 'shipDate',
    id: 'shipDate',
    label: 'Ship Date',
    placeholder: '',
    type: 'calendar',
    error: 'top',
    required: true,
  },
];
