import { Fragment, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { cn } from '@/utils/helper';
import { CheckIcon } from '@/components/shared/Icons';
import { useOrder, useError } from '@/context';
import { Stripe } from './Stripe';
import { Plaid } from './Plaid';
import styles from './PaymentOptions.module.scss';
import { PartnerCheckout } from './PartnerCheckout';
import { Spinner } from '@maker-ui/loaders';

interface PaymentType {
  type: 'ACH' | 'Credit Card';
}

const paymentTypes: { type: PaymentType['type']; label: string }[] = [
  { type: 'Credit Card', label: 'Pay with Credit Card' },
  { type: 'ACH', label: 'Pay with Bank' },
];

const timeConstraint = 1000 * 60 * 20; // 20 minutes

/**
 * The `PaymentOptions` component renders a Stripe Elements form and a
 * button that triggers the Plaid client overlay.
 *
 * @param nextStep - control of the order form stepper
 */
export const PaymentOptions = () => {
  const { paymentType, setPaymentType, isProcessing, order } = useOrder();
  const router = useRouter();
  const { setError } = useError();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function goToOrderConfirmation() {
    router.push('/order-confirmation');
  }

  useEffect(() => {
    const handleTimeout = () => setError('TIMEOUT');

    // Reset timeout to ensure full timeConstraint duration on visibility change to visible
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        clearTimeout(timeoutRef.current!);
        timeoutRef.current = setTimeout(handleTimeout, timeConstraint);
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    // Initial setup of timeout
    timeoutRef.current = setTimeout(handleTimeout, timeConstraint);

    return () => {
      clearTimeout(timeoutRef.current!);
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [setError]);

  return paymentType === 'Partner Order' && !order?.partner?.discount ? (
    <PartnerCheckout />
  ) : (
    <Fragment key={`${order?.partner?.discount || 0}-key`}>
      {order?.partner?.discount && (
        <div className={cn(styles.banner, 'flex align-center justify-center')}>
          Partner Discount Applied - {order.partner.discount * 100}% OFF
        </div>
      )}
      <div data-cy="payment-options" className="options-selector">
        <h3>Payment Options</h3>
        <div className={styles.grid}>
          {paymentTypes.map(({ type, label }) => (
            <div
              key={type}
              className={cn(
                styles.grid_item,
                'flex align-start justify-center flex-col',
                paymentType === type ? styles.active : undefined
              )}>
              <button
                data-cy={`btn-payment-${type === 'ACH' ? 'plaid' : 'stripe'}`}
                onClick={() => setPaymentType(type)}
                className={cn(
                  styles.btn_select,
                  paymentType === type ? styles.hidden : undefined,
                  'absolute cover'
                )}
              />
              <div
                className={cn(
                  styles.title,
                  'flex align-center justify-center'
                )}>
                <CheckIcon />
                <span>{label}</span>
              </div>
              {type === 'Credit Card' ? (
                <Stripe nextStep={goToOrderConfirmation} />
              ) : (
                <Plaid nextStep={goToOrderConfirmation} />
              )}
            </div>
          ))}
        </div>
        {isProcessing && (
          <div
            className="flex align-center justify-center"
            style={{ marginTop: 40 }}>
            <Spinner
              css={{ height: 70, marginRight: 5 }}
              type="bars"
              colors={{
                primary: '#9CCD32',
                secondary: '#bedf78',
                third: '#a9b199',
                fourth: '#797d71',
              }}
            />
            Processing order...
          </div>
        )}
      </div>
    </Fragment>
  );
};
