export const fields = [
  {
    name: 'name',
    id: 'name',
    label: 'Name',
    placeholder: 'Name for shipping',
    type: 'text',
    error: 'top',
    required: true,
  },
  {
    name: 'email',
    id: 'email',
    label: 'Email Address',
    placeholder: 'Email Address',
    type: 'email',
    error: 'top',
    required: true,
  },
  {
    name: 'phone',
    id: 'phone',
    label: 'Telephone',
    placeholder: 'Telephone',
    type: 'text',
    error: 'top',
    required: true,
  },
  {
    name: 'audienceType',
    id: 'audienceType',
    label: 'Audience Type',
    type: 'select',
    error: 'top',
  },
  {
    name: 'pickupDate',
    id: 'pickupDate',
    label: 'Pickup Date',
    placeholder: '',
    type: 'calendar',
    error: 'top',
    required: true,
  },
];
