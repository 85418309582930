import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn } from '@/utils/helper';
import styles from './AppTitle.module.scss';

interface FormHeaderProps {
  currentStep: number;
  product?: string;
}

/**
 * The `AppTitle` component is only visible on Step 1 of the form and
 * displays the HempWool logo.
 */
export const AppTitle = ({ product, currentStep }: FormHeaderProps) => {
  const ref = useRef(null);
  const title = product === 'hempwool' ? 'HempWool' : 'Hempcrete';

  return (
    <CSSTransition
      in={currentStep === 1}
      timeout={300}
      classNames="app-title"
      nodeRef={ref}>
      <div
        ref={ref}
        className={cn(
          styles.root,
          'flex align-center justify-center flex-col'
        )}>
        <h1 className={styles.title}>{title}</h1>
        <h3>Buy Direct</h3>
      </div>
    </CSSTransition>
  );
};
