import { useFormikContext } from 'formik';
import { BackIcon } from '@/components/shared/Icons';
import { HempitectureLogo } from '@/components/shared/Logos';
import { type ProductType, useOrder } from '@/context';
import { cn } from '@/utils/helper';
import styles from './Header.module.scss';

interface HeaderProps {
  currentStep: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const buttons: { label: string; value: ProductType }[] = [
  { label: 'HempWool', value: 'hempwool' },
  { label: 'Hempcrete', value: 'hempcrete' },
];

/**
 * The `Header` component displays the Hempitecture logo and a conditional back button
 * that lets users navigate to previous pages of the order form.
 */
export const Header = ({ currentStep, setStep }: HeaderProps) => {
  const { order, setProductType } = useOrder();
  const { setFieldValue }: any = useFormikContext();

  const handleProductChange = (type: ProductType) => {
    if (order.productType !== type) {
      setStep(1);
      setProductType(type);
      setFieldValue('products', [
        {
          sku: 0,
          sqft: 0,
          pallets: 0,
        },
      ]);
    }
  };

  return (
    <div className={cn(styles.header, 'flex align-center justify-between')}>
      <div className="flex align-center">
        <a
          href="https://hempitecture.com"
          target="_blank"
          rel="noopener noreferrer"
          className={'flex align-center btn-naked'}>
          <HempitectureLogo className={styles.logo} />
        </a>
        {currentStep < 3 && (
          <div className="flex">
            {buttons.map(({ label, value }) => (
              <button
                key={value}
                className={cn(
                  styles.toggle,
                  order.productType === value ? styles.active : undefined
                )}
                onClick={() => handleProductChange(value)}>
                {label}
              </button>
            ))}
          </div>
        )}
      </div>
      <button
        data-cy="btn-back"
        className={cn(
          styles.back,
          currentStep > 1 && currentStep < 5 ? styles.active : undefined,
          'flex align-center btn-naked'
        )}
        onClick={() => setStep(currentStep - 1)}>
        <BackIcon />
        Back
      </button>
    </div>
  );
};
