import { useFormikContext, Field, FormikErrors, FormikTouched } from 'formik';
import type { FormValues } from '@hempitecture/types';

import { FormStepProps } from '../schema';
import { useOrder } from '@/context';
import { fields } from './fields';
import { DatePickerField } from '../DatePickerField';
import { cn } from '@/utils/helper';
import styles from './PickupForm.module.scss';

/**
 * The `InfoForm` component is Step 1 of the order form and is responsible
 * for capturing basic lead information. Upon completion, it will fire a request
 * to add or retrieve a lead associated with the customer email address.
 *
 * @param nextStep - control of the order form stepper
 */
export const PickupForm = ({ nextStep }: FormStepProps) => {
  const { updateLead, order, setPickupLocation } = useOrder();
  const {
    errors,
    touched,
    values,
  }: {
    errors: FormikErrors<FormValues>;
    touched: FormikTouched<FormValues>;
    values: FormValues;
  } = useFormikContext();

  const validated =
    !errors.name &&
    values.name &&
    !errors.email &&
    values.email &&
    !errors.phone &&
    values.phone
      ? true
      : false;

  function showValidation(key: string): boolean {
    return errors[key] && touched[key] ? true : false;
  }

  function updateLocation(e) {
    if (e.target.checked) {
      setPickupLocation(e.target.value);
    }
  }

  // Hempcrete can only be picked up in Jerome, ID
  const options =
    order?.productType === 'hempcrete'
      ? order?.pickupLocations?.filter(
          ({ locationKey }) => locationKey === 'ID'
        )
      : order?.pickupLocations;

  return (
    <>
      {fields.map(({ name, id, label, placeholder, type, error, required }) => (
        <div key={id} className="field-container">
          <label htmlFor={id}>
            {label}
            {required ? <span className="required">*</span> : null}
          </label>
          {type === 'select' ? (
            <Field
              as="select"
              name={name}
              className={showValidation(name) ? 'error' : ''}>
              <option value="placeholder">Select an Audience Type</option>
              <option value="Homeowner">Homeowner</option>
              <option value="Specifier">Specifier (architect/designer)</option>
              <option value="Installer">
                Installer (general or subcontractor)
              </option>
              <option value="Other">Other</option>
            </Field>
          ) : type === 'calendar' ? (
            <>
              <div
                className="description"
                style={{
                  margin: '0 0 30px 30px',
                  fontSize: 14,
                  color: '#383838',
                }}>
                Please select your preferred pickup date.
              </div>
              <DatePickerField id={id} name={name} delivery="pickup" />
            </>
          ) : (
            <Field
              id={id}
              name={name}
              className={showValidation(name) ? 'error' : ''}
              placeholder={placeholder}
              type={type}
            />
          )}

          {showValidation(name) && (
            <div
              className="form-error"
              data-cy="form-error"
              style={{
                top: error === 'top' ? -5 : 'calc(100% - 5px)',
              }}>
              {errors[name]}
            </div>
          )}
        </div>
      ))}
      <div data-cy="pickup-options">
        <div className={styles.instructions}>
          Please select your nearest fulfillment center:
        </div>
        {options
          ? options.map((location, index) => (
              <div
                key={location.locationKey}
                data-cy={`pickup-option-${index}`}
                className={cn(
                  styles.option,
                  'flex',
                  location.cityName === order.selectedPickupLocation?.cityName
                    ? styles.selected
                    : null
                )}>
                <input
                  id={`location-${location.cityName}`}
                  type="radio"
                  value={location.cityName}
                  checked={order.selectedPickupLocation === location}
                  name="pickup-location"
                  onChange={updateLocation}
                />
                <label htmlFor={`location-${location.cityName}`}>
                  <div>
                    <strong>{location.name}</strong>
                  </div>
                  <div>{location.line1}</div>
                  <div>{location.line2}</div>
                  <div>
                    {location.cityAlias ?? location.cityName},{' '}
                    {location.stateCode}, {location.postalCode}
                  </div>
                  <div>{location.countryCode === 'US' ? 'USA' : 'Canada'}</div>
                </label>
                <a href={location.mapUrl} target="_blank" rel="noreferrer">
                  View Map
                </a>
              </div>
            ))
          : 'Sorry, none of our fulfillment centers are currently available for pickup.'}
        <div className={styles.pickup_message}>
          After you place your order, our client success team will reach out to
          schedule a pickup time.
        </div>
      </div>
      <button
        type="button"
        data-cy="submit-lead"
        disabled={!validated}
        className={
          validated && order.selectedPickupLocation
            ? 'active btn-next btn'
            : 'btn-next btn'
        }
        onClick={() => {
          if (validated && order.selectedPickupLocation) {
            updateLead({ shipDetails: false });
            nextStep('Selected Pickup');
          }
        }}>
        Checkout
      </button>
    </>
  );
};
