import { useEffect, useState } from 'react';
import { Carousel as MKUICarousel } from '@maker-ui/carousel';
import { useFormikContext } from 'formik';
import Image, { StaticImageData } from 'next/image';
import type { FormValues } from '@hempitecture/types';

import { useOrder } from '@/context';
import { ArrowIcon } from '@/components/shared/Icons';
import styles from './Carousel.module.scss';

interface ImageSlideProps {
  url?: string | StaticImageData;
  name?: string;
  depth?: string | number;
  price?: string | number;
  salePrice?: string | number;
  saleLabel?: string;
  priceUnit?: 'SqFt' | 'Lb';
  type?: string;
}

/**
 * The `ImageSlide` component template houses product photos for the image slider.
 */

const ImageSlide = ({
  url,
  name,
  price,
  priceUnit,
  depth,
  type,
  salePrice,
  saleLabel,
}: ImageSlideProps) => {
  const showPrice = price && priceUnit;
  const isSale = !!salePrice;

  const priceString = `$${price} / ${priceUnit}`;
  const saleString = isSale ? `$${salePrice} / ${priceUnit}` : undefined;

  return (
    <div className={styles.slide}>
      {showPrice ? (
        <div className={styles.slide_header}>
          <h4>{name}</h4>
          {type === 'hempwool' ? (
            <div>
              {depth}&quot; Deep -{' '}
              <span className={isSale ? styles.strikethrough : undefined}>
                <span className={styles.price}>{priceString}</span>
              </span>
              {isSale && (
                <span className={styles.sale_price}>{saleString}</span>
              )}
            </div>
          ) : (
            <div>
              <span className={isSale ? styles.strikethrough : undefined}>
                <span className={styles.price}>{priceString}</span>
              </span>
              {isSale && (
                <span className={styles.sale_price}>{saleString}</span>
              )}
            </div>
          )}
          {isSale && saleLabel && (
            <div className={styles.sale_label}>{saleLabel}</div>
          )}
        </div>
      ) : null}
      <div className={styles.image_container}>
        {url ? (
          <Image
            unoptimized
            src={url}
            alt={name || 'Hempwool'}
            layout="fill"
            objectFit="contain"
          />
        ) : null}
      </div>
    </div>
  );
};

/**
 * The `ImageSlider` component renders a sliding / draggable carousel with
 * dots and navigation. This is displayed next to the form.
 */

export const Carousel = ({ step }: { step: number }) => {
  const [slide, setSlide] = useState(0);
  const { order } = useOrder();
  const { values }: { values: FormValues } = useFormikContext();

  function formatMedia() {
    let media: ImageSlideProps[] = [];
    let supplementaryMedia: ImageSlideProps[] = [];
    const activeType = order.productType.toLowerCase();

    Object.keys(order.inventory).forEach((sku) => {
      const t =
        order.inventory[sku]?.productType?.toLowerCase() !== 'hempwool'
          ? 'hempcrete'
          : 'hempwool';
      if (t !== activeType) return;
      if (!order.inventory[sku]?.image) return;
      // Check for sale prices
      const isSale =
        order.inventory[sku]?.lbPriceRetail ||
        order.inventory[sku]?.sqftPriceRetail;

      const name =
        activeType === 'hempwool'
          ? order.inventory[sku].shortName
          : order.inventory[sku].name;
      const price = isSale
        ? order.inventory[sku]?.lbPriceRetail ??
          order.inventory[sku]?.sqftPriceRetail
        : order.inventory[sku]?.lbPrice ?? order.inventory[sku]?.sqftPrice;
      const salePrice = isSale
        ? order.inventory[sku]?.lbPrice ?? order.inventory[sku]?.sqftPrice
        : undefined;
      const priceUnit = order.inventory[sku]?.lbPrice ? 'Lb' : 'SqFt';
      const depth = order.inventory[sku]?.depth;

      if (order.inventory[sku].image.length > 1) {
        order.inventory[sku].image.forEach((image, i) => {
          if (i === 0) return;
          supplementaryMedia.push({
            url: image.url,
            type: activeType,
          });
        });
      }

      media.push({
        name,
        url: order.inventory[sku].image[0]?.url,
        depth: depth ? depth.toFixed(1) : undefined,
        price: price ? price.toFixed(2) : undefined,
        salePrice: salePrice ? salePrice.toFixed(2) : undefined,
        saleLabel: order.inventory[sku].saleLabel,
        priceUnit,
        type: activeType,
      });
    });

    // Sort by Depth for Hempwool
    if (activeType === 'hempwool') {
      media = media.sort((a, b) => {
        if (a.depth && b.depth) {
          return Number(a.depth) - Number(b.depth);
        }
        return 0;
      });
    }

    media = [...media, ...supplementaryMedia];

    return media;
  }

  let data = formatMedia();

  useEffect(() => {
    setSlide(0);
  }, [order.productType]);

  // Set the image slider to display an image of the first product SKU
  useEffect(() => {
    // if (order.productType === 'hempcrete') return;
    if (values.products[0] !== undefined) {
      const sku = values.products[0].sku;

      if (sku !== 0) {
        data?.forEach(({ name }, index) => {
          if (
            order.productType === 'hempwool' &&
            name &&
            order.inventory[sku].name?.startsWith(name)
          ) {
            setSlide(index);
          }
          if (
            order.productType === 'hempcrete' &&
            name &&
            order.inventory[sku].name?.includes(name)
          ) {
            setSlide(index);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products, order.inventory, order.productType]);

  return (
    <MKUICarousel
      key={order.productType}
      controls={[slide, setSlide]}
      css={{
        display: [step > 1 ? 'none' : 'flex', 'flex'],
        height: [600, 'auto'],
        flex: 1,
        '.slide-container': {
          height: [320, 'auto'],
        },
        '.slide': {
          touchAction: 'none',
        },
        '.carousel-nav': {
          height: [40, 60],
          width: [40, 60],
          background: 'rgba(255,255,255,0.5)',
          borderRadius: '50%',
          padding: 0,
          outline: 'none',
          svg: {
            height: '100%',
            width: '100%',
          },
        },
      }}
      data={data}
      template={<ImageSlide />}
      settings={{
        arrowPadding: 20,
        arrowMargin: 20,
        arrow: <ArrowIcon />,
        draggable: false,
        dotColorActive: 'var(--color-primary)',
        dotColorMuted: 'var(--color-gray)',
        autoPlay: false,
      }}
    />
  );
};
