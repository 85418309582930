import { formatNumber } from '@hempitecture/utils';
import type { MultiShipment, Shipment } from '@hempitecture/types';

import { useShipment } from '@/context';
import { cn } from '@/utils/helper';
import styles from './ShipOptions.module.scss';

interface QuoteButtonProps {
  item: Partial<Shipment>;
  multiShipment?: MultiShipment;
}

export const QuoteButton = ({ item, multiShipment }: QuoteButtonProps) => {
  const { shipping, setShipment, setMultiShipment } = useShipment();

  const deliveryDate = item?.deliveryDate ? new Date(item.deliveryDate) : null;

  function getClassName() {
    if (
      shipping.shipmentType === 'Multi-LTL' &&
      shipping.multiShipment?.totalRate === item.totalRate
    ) {
      return styles.active;
    }

    if (
      shipping.shipmentType !== 'Multi-LTL' &&
      shipping.shipment?.quoteId === item.quoteId
    ) {
      return styles.active;
    }

    return undefined;
  }
  return (
    <button
      className={cn(
        styles.button,
        getClassName(),
        'flex flex-wrap align-center bold'
      )}
      data-cy="btn-quote"
      onClick={() => {
        if (shipping.shipmentType === 'Multi-LTL' && multiShipment) {
          setMultiShipment(multiShipment);
        } else {
          setShipment(item as Shipment);
        }
      }}>
      <div
        className={cn(
          styles.carrier_row,
          'flex align-center justify-start flex-1 flex-wrap'
        )}>
        <div className={styles.carrier}>
          {shipping.shipmentType === 'TL' ? 'Full Truckload' : item.carrier}
        </div>
      </div>
      <div className={cn(styles.row, 'flex align-center justify-end flex-1')}>
        <div
          className={cn(
            styles.row_inner,
            'flex align-center',
            shipping.shipmentType === 'TL' ? styles.center : null
          )}>
          {item.transitTime ? (
            <div className={styles.column}>
              <h4>Transit Time</h4>
              <div className={styles.transit_time}>
                {item.transitTime} Day
                {item.transitTime > 1 ? 's' : ''}
              </div>
            </div>
          ) : null}
          {deliveryDate ? (
            <div className={styles.column}>
              <h4>Est. Delivery</h4>
              <div className={styles.delivery_date}>
                <div className={styles.day}>
                  {deliveryDate.toLocaleString('default', {
                    day: 'numeric',
                  })}
                </div>
                <div className={styles.month}>
                  {deliveryDate.toLocaleString('default', {
                    month: 'short',
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={cn(styles.price, 'flex align-center')}>
          ${formatNumber(item.totalRate)}
        </div>
      </div>
    </button>
  );
};
