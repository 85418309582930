import { useRef, useState } from 'react';
import { generateId } from 'maker-ui';
import { InfoIcon } from '@/components/shared/Icons';
import { cn } from '@/utils/helper';
import styles from './InfoPopup.module.scss';

export interface Position {
  x: 'left' | 'center' | 'right' | 'origin';
  y: 'top' | 'center' | 'bottom';
}

interface InfoPopupProps {
  children: React.ReactNode;
}

export const InfoPopup = ({ children }: InfoPopupProps) => {
  const buttonRef = useRef(null);
  const [show, set] = useState(false);
  const [tooltipId] = useState(generateId());

  return (
    <div className={styles.root}>
      <button
        ref={buttonRef}
        type="button"
        className="btn-naked"
        onFocus={() => set(true)}
        onBlur={() => set(false)}
        onClick={() => set(!show)}
        aria-describedby={tooltipId}>
        <InfoIcon />
      </button>
      <div
        id={tooltipId}
        className={cn(styles.content, show ? styles.active : undefined)}>
        {children}
      </div>
    </div>
  );
};
