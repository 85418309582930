import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getGrandTotal } from '@hempitecture/utils';
import { useFormikContext } from 'formik';
import { FormValues } from '@hempitecture/types';

import { useOrder, useShipment } from '@/context';
import { cn, formatUSD } from '@/utils/helper';
import styles from './PaymentOptions.module.scss';

export const PartnerCheckout = () => {
  const router = useRouter();
  const { values }: { values: FormValues } = useFormikContext();
  const { order, createOrder, setIsProcessing } = useOrder();
  const { shipping, freeShipping, orderShipment } = useShipment();

  const { total, total_formatted } = getGrandTotal({
    inventory: order.inventory,
    products: values.products,
    shipping: shipping.shippingRate,
    freeShipping,
  });

  const maximumError = total > order.partner.maxValue;

  async function completeCheckout() {
    setIsProcessing(true);
    if (order.fulfillmentType === 'Delivery') {
      await orderShipment({
        orderType: 'Partner Order',
        stripeId: 'N/A',
        stripeTotal: total,
      });
    } else {
      createOrder({
        orderType: 'Partner Order',
        stripeId: 'N/A',
        stripeTotal: total,
        pickupTaxRate: order.taxRates[order.selectedPickupLocation.stateCode],
        orderNumber: Math.floor(100000 + Math.random() * 900000),
      });
    }
  }

  useEffect(() => {
    if (order.orderComplete) {
      router.push('/order-confirmation');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderComplete]);

  return (
    <>
      <div className={cn(styles.banner, 'flex align-center justify-center')}>
        Preferred Partner - Payments Disabled
      </div>
      <div className={styles.partner}>
        <h2>Partner Checkout</h2>
        <p>
          Please review the information on this screen to ensure order accuracy.
          After placing your order, you will receive an email receipt and a
          member from our team will reach out to you soon.
        </p>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>Organization Name</td>
              <td>{order.partner.name}</td>
            </tr>
            <tr>
              <td>Monthly Credit Line</td>
              <td>{formatUSD(order.partner.maxValue)}</td>
            </tr>
            <tr className={cn(styles.line, maximumError ? styles.error : null)}>
              <td>Order Total</td>
              <td>${total_formatted}</td>
            </tr>
          </tbody>
        </table>
        {maximumError && (
          <div className={styles.max_error}>
            Order total exceeds your monthly credit line. Please contact your
            Hempitecture sales rep.
          </div>
        )}
        <button
          onClick={completeCheckout}
          disabled={maximumError}
          className={cn(styles.btn_submit, 'btn')}>
          {maximumError ? 'Unavailable' : 'Complete Order'}
        </button>
      </div>
    </>
  );
};
