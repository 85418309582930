import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import { useOrder } from '@/context';
import { getStartDate, findNextWeekday, isNotWeekend } from '@/utils/dates';

/**
 * The `DatePicker` component lets users select a weekday pickup date.
 */
export const DatePickerField = ({ delivery = 'shipping', ...props }) => {
  const dayBuffer = 7;
  const { setFieldValue } = useFormikContext();
  const { order } = useOrder();
  //@ts-ignore
  const [field] = useField({ ...props });

  const type =
    order.palletCount >= 13
      ? 'TL'
      : order.palletCount <= 6
      ? 'LTL'
      : 'Multi-LTL';

  const today = dayjs();
  const minDate = getStartDate(dayBuffer);

  const maxDate =
    type === 'TL' && delivery === 'shipping'
      ? today.add(dayBuffer + (14 - dayBuffer), 'day').toDate() // ensuring we account for the day buffer
      : undefined;

  function validateSelected() {
    const date = dayjs(field.value);
    return date.isBefore(minDate)
      ? findNextWeekday(minDate).toDate()
      : date.toDate();
  }

  return (
    <>
      <div className="flex justify-center">
        <DatePicker
          inline
          {...field}
          filterDate={isNotWeekend}
          minDate={minDate.toDate()}
          maxDate={maxDate}
          selected={validateSelected()}
          onChange={(val: Date) => {
            setFieldValue(field.name, val.toISOString());
          }}
        />
      </div>
      {type === 'TL' && delivery === 'shipping' ? (
        <div data-cy="full-tl-notice" style={{ marginTop: 10, fontSize: 14 }}>
          **Full Truckload shipments must be booked within 14 days of the order
          date.
        </div>
      ) : null}
    </>
  );
};
