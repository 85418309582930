import { useEffect, useState } from 'react';
import { Spinner } from '@maker-ui/loaders';

import { QuoteButton } from './QuoteButton';
import { useShipment } from '@/context';
import { useQuoteFetcher } from '@/hooks';
import { cn } from '@/utils/helper';
import styles from './ShipOptions.module.scss';

const loadString1 = 'Finding your nearest distributor...';
const loadString2 = 'Fetching the latest shipping quotes...';
const loadString3 = 'Almost there...';

// Utility function to create a delay
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * The `ShipOptions` component dispatches a request for shipping quotes. It
 * renders an option button for each quote.
 */
export const ShipOptions = () => {
  const [loadingText, setLoadingText] = useState(loadString1);
  const { shipping } = useShipment();
  const { isLoading, isLoadingMore, quotes, multiQuotes } = useQuoteFetcher();

  useEffect(() => {
    // Update text of loading indicator
    const updateLoadingText = async () => {
      if (isLoading) {
        await delay(5000);
        setLoadingText(loadString2);
        await delay(5000);
        setLoadingText(loadString3);
      } else {
        setLoadingText(loadString1);
      }
    };

    updateLoadingText();
  }, [isLoading]);

  return (
    <div
      className={cn(styles.root, 'shipping-options options-selector')}
      data-cy="shipping-options">
      <div
        className={cn(
          styles.container,
          'flex justify-between align-start flex-wrap'
        )}>
        <h3>Shipping Options</h3>
        {!isLoading && shipping.closestDistributor ? (
          <div className={styles.location}>
            <strong>Shipping Origin:</strong>{' '}
            {shipping.closestDistributor.cityAlias},{' '}
            {shipping.closestDistributor.stateCode}
          </div>
        ) : null}
      </div>
      {shipping.emptyQuotes === 2 ? (
        <div>
          Sorry, we couldn&apos;t find any carriers. Please update your shipping
          options or check back later.
        </div>
      ) : isLoading ? (
        <div
          data-cy="loader-shipping"
          className="flex align-center justify-center flex-col">
          <Spinner
            css={{ height: [100, 180], width: [100, 180] }}
            type="bars"
            colors={{
              primary: '#9CCD32',
              secondary: '#bedf78',
              third: '#a9b199',
              fourth: '#797d71',
            }}
          />
          <div className={styles.load_message}>{loadingText}</div>
        </div>
      ) : (
        <>
          <div className={styles.quote_group}>
            {quotes &&
              quotes.map((item, index) => (
                <QuoteButton key={index} item={item} />
              ))}
            {shipping.shipmentType !== 'TL' && isLoadingMore ? (
              <div className="flex align-center justify-center">
                <Spinner
                  css={{ height: [50, 70], marginTop: -10 }}
                  type="bars"
                  colors={{
                    primary: '#9CCD32',
                    secondary: '#bedf78',
                    third: '#a9b199',
                    fourth: '#797d71',
                  }}
                />
              </div>
            ) : null}
            {shipping.shipmentType === 'Multi-LTL' &&
              multiQuotes?.map((item, index) => (
                <QuoteButton
                  key={index}
                  multiShipment={item}
                  item={{
                    carrier:
                      item.carrier1.name === item.carrier2.name
                        ? item.carrier1.name
                        : `${item.carrier1.name} and ${item.carrier2.name}`,
                    totalRate: item.totalRate,
                    transitTime: item.transitTime,
                    deliveryDate: item.deliveryDate,
                    shipDate: item.shipDate,
                  }}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
