import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import type { FormValues } from '@hempitecture/types';

import { PlaidLogo } from '@/components/shared/Icons';
import { useShipment, useOrder, useError } from '@/context';
import { cn } from '@/utils/helper';
import styles from './PaymentOptions.module.scss';

interface SuccessProps {
  public_token: string;
  account_id: string;
}

/**
 * The `Plaid` component handles all logic related to instant ACH
 * payment with Plaid.
 *
 * @param nextStep - control of the order form stepper
 */
export const Plaid = ({ nextStep }: { nextStep: () => void }) => {
  const { values }: { values: FormValues } = useFormikContext();
  const { order, paymentType, setStripeDetails, createOrder, setIsProcessing } =
    useOrder();
  const { shipping, orderShipment, freeShipping } = useShipment();
  const [config, setConfig] = useState(null);
  const { setError } = useError();

  useEffect(() => {
    initializePlaid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order.orderComplete) {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderComplete]);

  /** Step 1:
   * Initialize Plaid and retrieve public token from server, then
   * save the configuration object to state
   * */

  async function initializePlaid() {
    try {
      const linkToken = await fetch('/api/payments/get-plaid-link-token').then(
        (res) => res.json()
      );

      const configs = {
        token: linkToken.link_token,
        onLoad: () => {},
        onSuccess: (public_token, metadata) => {
          createBankCharge({
            public_token: public_token,
            account_id: metadata.accounts[0].id,
          });
        },
        onExit: async (err, metadata) => {
          // The user exited the Link flow.
          if (err != null) {
            throw new Error(err);
          }
        },
      };
      setConfig(configs);
    } catch (err) {
      // Todo handle error
      console.error(err);
    }
  }

  /** Step 2:
   * Use the Plaid link token to retrieve a Stripe Bank Account token on
   * the server and create a charge.
   * */

  async function createBankCharge(props: SuccessProps) {
    try {
      setIsProcessing(true);
      const res = await fetch('/api/payments/create-plaid-charge', {
        method: 'POST',
        body: JSON.stringify({
          public_token: props.public_token,
          account_id: props.account_id,
          items: values.products,
          shipRate:
            order.fulfillmentType === 'Delivery'
              ? shipping.shippingRate
              : undefined,
          freeShipping,
          discount: order?.partner?.discount,
          taxRate:
            order.fulfillmentType === 'Delivery'
              ? order.taxRates[values.state]
              : order.taxRates[order.selectedPickupLocation.stateCode],
        }),
      }).then((res) => {
        if (res.status === 500) {
          setError('ACH');
        }
        return res.json();
      });

      if (res.stripeId) {
        if (order.fulfillmentType === 'Delivery') {
          await orderShipment({
            stripeId: res.stripeId,
            stripeTotal: res.amount / 100,
            orderType: 'ACH',
          });
        } else {
          createOrder({
            orderType: 'ACH',
            stripeTotal: res.amount / 100,
            stripeId: res.stripeId,
            orderNumber: Math.floor(100000 + Math.random() * 900000),
            pickupTaxRate:
              order.taxRates[order.selectedPickupLocation.stateCode],
          });
        }

        setStripeDetails(res.stripeId, res.amount / 100);
      }
    } catch (err) {
      setError('ACH');
    }
  }

  async function handleClick() {
    // @ts-expect-error - Plaid library issues
    const Plaid = window.Plaid;
    const linkHandler = Plaid.create(config);

    linkHandler.open();
  }

  return (
    <div
      className={cn(
        styles.pay_wrapper,
        'flex align-center justify-center flex-col'
      )}>
      <span>No transaction fee with ACH Payment.</span>
      <div className="flex-1 text-center">
        <PlaidLogo style={{ height: 50, marginBottom: 10 }} />
        <div>
          Make an instant ACH payment with{' '}
          <a href="https://plaid.com/" target="_blank" rel="noreferrer">
            Plaid
          </a>
          .
        </div>
      </div>
      <button
        data-cy="submit-plaid-payment"
        onClick={handleClick}
        disabled={paymentType !== 'ACH'}
        className={styles.btn_submit}>
        Submit Order
      </button>
    </div>
  );
};
