import { useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useOrder, useShipment } from '@/context';
import { PickupIcon, TruckIcon } from '@/components/shared/Icons';
import type { FormStepProps } from '../schema';
import { StepHeader } from '../StepHeader/StepHeader';
import { ShippingForm } from '../ShippingForm';
import { PickupForm } from '../PickupForm';
import styles from './FulfillmentForm.module.scss';
import { cn } from '@/utils/helper';

interface DeliveryMethod {
  type: 'Pickup' | 'Delivery';
  label: string;
  icon: React.ReactNode;
}

/**
 * The `FulFillmentForm` is Step 3 of the order form and is responsible for
 * collecting all customer shipping information. Upon completion, it initiates the
 * shipping quote flow.
 *
 * @param nextStep - control of the order form stepper
 */
export const FulFillmentForm = ({ nextStep }: FormStepProps) => {
  const ref = useRef(null);
  const { order, setFulfillmentType } = useOrder();
  const { shipping, getAPITokens } = useShipment();
  const isDelivery = order.fulfillmentType === 'Delivery';

  const methods: DeliveryMethod[] = [
    {
      type: 'Pickup',
      label: 'Pickup',
      icon: <PickupIcon style={{ height: 25 }} />,
    },
    {
      type: 'Delivery',
      label: 'Delivery',
      icon: <TruckIcon style={{ height: 32 }} />,
    },
  ];

  useEffect(() => {
    if (!shipping?.tokens) {
      getAPITokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cn(styles.root, 'fulfillment-form')}
      data-cy="fulfillment-form"
      style={{ paddingBottom: 100 }}>
      <StepHeader step={2}>Delivery Options</StepHeader>
      <div>
        How would you like to receive your order?
        <div className={styles.grid}>
          {methods.map(({ type, label, icon }, key) => (
            <button
              key={key}
              type="button"
              data-cy={`button-fulfillment-${type.toLowerCase()}`}
              className={type === order.fulfillmentType ? styles.active : null}
              onClick={() => setFulfillmentType(type)}>
              {icon}
              <span>{label}</span>
            </button>
          ))}
        </div>
        {order.productType === 'hempcrete' &&
        order.hempcreteType === 'block' ? (
          <div className={styles.notice}>
            Please allow 6-8 weeks for stateside delivery or pickup of Hempcrete
            blocks.
          </div>
        ) : null}
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={order.fulfillmentType}
          timeout={300}
          classNames="fade"
          nodeRef={ref}>
          {isDelivery ? (
            <div ref={ref}>
              <ShippingForm nextStep={nextStep} />
            </div>
          ) : order.fulfillmentType === 'Pickup' ? (
            <div ref={ref}>
              <PickupForm nextStep={nextStep} />
            </div>
          ) : (
            <div></div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
