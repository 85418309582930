import { useEffect, useRef, useState } from 'react';
import { FormikErrors, useFormikContext } from 'formik';
import type { FormValues } from '@hempitecture/types';

import { useOrder, useShipment } from '@/context';
import { RefreshIcon } from '@/components/shared/Icons';
import { FormStepProps } from '../schema';
import { ShippingFields } from './ShippingFields';
import { useEvent, useQuoteFetcher } from '@/hooks';
import styles from './ShippingForm.module.scss';
import { cn } from '@/utils/helper';

/**
 * The `ShippingForm` is Step 3 of the order form and is responsible for
 * collecting all customer shipping information. Upon completion, it initiates the
 * shipping quote flow.
 *
 * @param nextStep - control of the order form stepper
 */
export const ShippingForm = ({ nextStep }: FormStepProps) => {
  const ref = useRef(null);
  const [pending, setPending] = useState(false);
  const { order, updateLead } = useOrder();
  const { formatShippingParams, shipping, getAPITokens, saveRate } =
    useShipment();
  const { resetQuote, requestSubmitted, fetchQuotes } = useQuoteFetcher();
  const { createEvent } = useEvent();

  useEffect(() => {
    if (!shipping?.tokens) {
      getAPITokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setPrimusRates() {
    const isMulti = shipping.shipmentType === 'Multi-LTL';
    if (isMulti && shipping.multiShipment?.originApi === 'PRIMUS') {
      saveRate([
        shipping.multiShipment.carrier1.quoteId,
        shipping.multiShipment.carrier2.quoteId,
      ]);
    }
    if (!isMulti && shipping.shipment?.originApi === 'PRIMUS') {
      saveRate([shipping.shipment.quoteId]);
    }
  }

  function scrollToTop() {
    if (document) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }
  }

  async function fetchRates() {
    scrollToTop();
    const quoteParams = await formatShippingParams();
    createEvent('Requested Shipping');
    await fetchQuotes(quoteParams);
  }

  const { errors }: { errors: FormikErrors<FormValues>; values: FormValues } =
    useFormikContext();

  const validated =
    errors['address'] ||
    errors['state'] ||
    errors['city'] ||
    errors['zip'] ||
    errors['phone']
      ? false
      : true;

  return (
    <div ref={ref}>
      <ShippingFields />
      {order.palletCount < 12 && order.palletCount > 6 ? (
        <div data-cy="multi-ltl-notice" className={styles.notice}>
          <h4>Notice</h4>
          <p>
            Due to your order size, we need to dispatch 2 partial truckloads.
            Your quote will reflect 2 shipments for the same date and may
            include different carriers.
          </p>
          <p>
            For orders of more than 6 pallets, you can contact us directly to
            check negotiated shipping rates with our other partners.
          </p>
        </div>
      ) : null}
      <div style={{ margin: '10px 0', fontSize: 14, color: '#383838' }}>
        *These dates are not guaranteed. Customer service will follow up with
        your estimated shipping date.
      </div>
      {requestSubmitted &&
      !shipping.shipment &&
      !shipping.multiShipmentSelected ? (
        <div style={{ marginTop: 25, color: 'var(--color-red)' }}>
          Please select a shipping option to continue to order checkout.
        </div>
      ) : null}
      {requestSubmitted ? (
        <div className="flex center">
          <button
            type="button"
            className={cn(styles.refresh, 'btn')}
            data-cy="btn-refresh"
            onClick={() => {
              resetQuote();
              fetchRates();
            }}>
            <RefreshIcon
              style={{
                transition: 'transform ease-in-out .35s',
                transform: `rotate(${1 * 360}deg)`,
              }}
            />
            Refresh
          </button>
          <button
            type="button"
            data-cy="btn-checkout"
            className={
              shipping.shipment || shipping.multiShipmentSelected
                ? 'active btn-next btn'
                : 'btn-next btn'
            }
            style={{ textTransform: 'uppercase', marginLeft: 30 }}
            onClick={async () => {
              if (validated) {
                if (shipping.shipment || shipping.multiShipmentSelected) {
                  await updateLead({
                    shippingTotal:
                      shipping.shipmentType === 'Multi-LTL'
                        ? shipping.multiShipment.totalRate
                        : shipping.shipment.totalRate,
                    shipDetails: true,
                  });
                  setPrimusRates();
                  nextStep('Selected Shipping');
                }
              }
            }}>
            Checkout
          </button>
        </div>
      ) : (
        <button
          type="button"
          data-cy="submit-shipping"
          disabled={pending || !validated}
          className={validated ? 'active btn-next btn' : 'btn-next btn'}
          onClick={() => {
            if (validated) {
              setPending(true);
              resetQuote();
              fetchRates();
            }
          }}>
          Get Shipping Quotes
        </button>
      )}
    </div>
  );
};
