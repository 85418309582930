import { useFormikContext } from 'formik';
import { FormValues } from '@hempitecture/types';
import { getGrandTotal } from '@hempitecture/utils';

import { useShipment, useOrder } from '@/context';
import { PartnerLogin } from '@/components/checkout/PartnerLogin';
import { cn } from '@/utils/helper';
import styles from './Totalizer.module.scss';

export interface StepProps {
  step: number;
}

/**
 * The `Totalizer` component renders a running total of all items in
 * the user's cart as well as shipping. It is hidden for payment checkout.
 */
export const Totalizer = ({ step }: StepProps) => {
  const { values }: { values: FormValues } = useFormikContext();
  const { order } = useOrder();
  const { shipping, freeShipping } = useShipment();

  const { total_formatted } = getGrandTotal({
    inventory: order.inventory,
    products: values.products,
    shipping: shipping.shippingRate,
    discount: order.partner?.discount,
    freeShipping,
  });

  return (
    <div
      className={cn(
        styles.totalizer,
        step > 1 && step < 4 ? styles.active : undefined
      )}>
      <div
        className={cn(
          styles.container,
          step > 1 && step < 4 ? styles.active : undefined,
          'flex align-center justify-center flex-col'
        )}>
        <div className={styles.number} data-cy="totalizer">
          ${total_formatted}
        </div>
        <div className={styles.label}>Total</div>
      </div>
      {step === 3 ? <PartnerLogin /> : null}
    </div>
  );
};
