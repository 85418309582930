import { Fragment } from 'react';
import { cn } from '@/utils/helper';
import styles from './Stepper.module.scss';

interface StepperProps {
  currentStep: number;
  totalSteps: number;
}

/**
 * The `Stepper` component shows the user the current step of the
 * order form using dot indicators
 */

export const Stepper = ({ currentStep, totalSteps }: StepperProps) => {
  return (
    <div className={cn(styles.root, 'flex align-center justify-center')}>
      {[...Array(totalSteps)].map((e, i) => {
        const step = i + 1;
        return (
          <Fragment key={i}>
            <div
              key={i}
              data-cy={`stepper-${i + 1}`}
              className={cn(
                'flex align-center justify-center',
                styles.step,
                currentStep > step ? styles.complete : null,
                currentStep === step ? styles.active : null
              )}>
              <div className={styles.inner} />
            </div>
            {i !== totalSteps - 1 ? (
              <div
                className={cn(
                  styles.line,
                  currentStep > step ? styles.active : null
                )}
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};
