import { useField } from 'formik';
import { cn } from '@/utils/helper';
import styles from './ToggleField.module.scss';

interface ToggleFieldProps {
  id: string;
  name: string;
  disabled?: boolean;
}

/**
 * The `Toggle` component is a custom boolean form field that simulates
 * the iOS switch toggle.
 */
export const ToggleField = ({ id, disabled, ...props }: ToggleFieldProps) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  function handleKeyPress(e: React.KeyboardEvent<HTMLLabelElement>) {
    if (e.code !== 'Space') return;
    e.preventDefault();
  }

  return (
    <div className={cn(styles.switch, meta.value ? styles.active : undefined)}>
      <input
        type="checkbox"
        {...field}
        id={id}
        disabled={disabled}
        {...props}
      />
      {id ? (
        <label
          htmlFor={id}
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}>
          <span
            className={cn(styles.inner, disabled ? styles.disabled : undefined)}
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};
