import { Field, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { states, provinces } from '@hempitecture/utils';
import type { FormValues } from '@hempitecture/types';

import { DatePickerField } from '../DatePickerField';
import { useOrder } from '@/context';
import { DownIcon } from '@/components/shared/Icons';
import { InfoPopup } from '@/components/alerts/InfoPopup';
import { fields } from './fields';
import { ToggleField } from '../ToggleField';
import { cn } from '@/utils/helper';
import styles from './ShippingForm.module.scss';

export const ShippingFields = () => {
  const {
    errors,
    touched,
    values,
  }: {
    errors: FormikErrors<FormValues>;
    touched: FormikTouched<FormValues>;
    values: FormValues;
  } = useFormikContext();
  const { order } = useOrder();

  const isFTL = order.palletCount > 12;
  const stateKeys =
    values.country === 'US' ? Object.keys(states) : Object.keys(provinces);

  function showValidation(key: string): boolean {
    return errors[key] && touched[key] ? true : false;
  }

  return (
    <div className={styles.grid}>
      {fields.map(
        ({ name, id, label, placeholder, type, col, error, required }) =>
          name === 'liftgate' && isFTL ? null : type === 'text' ||
            type === 'email' ? (
            <div
              key={id}
              className="field-container"
              style={{
                gridColumn: col ? 'span 1' : 'span 2',
              }}>
              <label htmlFor={id}>
                {label}
                {required ? <span className="required">*</span> : null}
              </label>
              <Field
                id={id}
                name={name}
                className={showValidation(name) ? 'error' : ''}
                placeholder={placeholder}
                type={type}
              />
              {showValidation(name) && (
                <div
                  className="form-error"
                  style={{
                    top: error === 'top' ? -5 : 'calc(100% - 5px)',
                  }}>
                  {errors[name]}
                </div>
              )}
            </div>
          ) : type === 'switch' ? (
            <div key={id} className={cn('field-container', styles.ship_field)}>
              <div className={cn(styles.ship_label, 'label')}>{label}</div>
              <div className="flex align-center">
                <ToggleField id={id} name={name} />
                <div style={{ marginLeft: 20 }}>
                  {values[name] ? 'Yes' : 'No'}
                </div>
              </div>
            </div>
          ) : type === 'select' ? (
            <div
              key={id}
              className="field-container"
              style={{
                gridColumn: id === 'state' ? 'span 1' : 'span 2',
              }}>
              <div className="label">
                {label}
                {required ? <span className="required">*</span> : null}
              </div>
              <div className="custom-select">
                {id === 'state' ? (
                  <Field
                    as="select"
                    name="state"
                    className={showValidation(name) ? 'error' : ''}>
                    <option>
                      Select a {values.country === 'US' ? 'state' : 'province'}
                    </option>
                    {stateKeys.map((s) => (
                      <option key={s}>{s}</option>
                    ))}
                  </Field>
                ) : id === 'country' ? (
                  <Field
                    as="select"
                    name="country"
                    className={showValidation(name) ? 'error' : ''}>
                    <option>US</option>
                    <option>CA</option>
                  </Field>
                ) : id === 'audienceType' ? (
                  <Field
                    as="select"
                    name={name}
                    className={showValidation(name) ? 'error' : ''}>
                    <option value="placeholder">Select an Audience Type</option>
                    <option value="Homeowner">Homeowner</option>
                    <option value="Specifier">
                      Specifier (architect/designer)
                    </option>
                    <option value="Installer">
                      Installer (general or subcontractor)
                    </option>
                    <option value="Other">Other</option>
                  </Field>
                ) : null}
                <DownIcon className="down-caret" />
              </div>
              {showValidation(name) && (
                <div
                  className="form-error"
                  style={{
                    top: error === 'top' ? -5 : 'calc(100% - 5px)',
                  }}>
                  {errors[name]}
                </div>
              )}
            </div>
          ) : (
            <div
              key={id}
              className="field-container"
              style={{ marginTop: 20, gridColumn: 'span 2' }}>
              <div
                className="flex align-center bold"
                style={{
                  fontSize: 18,
                  color: '#6a6a6a',
                }}>
                {label}
                {required ? <span className="required">*</span> : null}
                <InfoPopup>
                  Due to global supply chain challenges, shipping rates are
                  unpredictable and costly. Hempitecture uses 3rd party shipping
                  services and orders are subject to our{' '}
                  <a
                    href="https://www.hempitecture.com/shipping-policy"
                    target="_blank"
                    rel="noreferrer">
                    Shipping Policy
                  </a>
                  .
                </InfoPopup>
              </div>
              <div className={styles.description}>
                Please select your preferred date for when the shipment should
                leave our distributor.
              </div>
              <DatePickerField id={id} name={name} />
              {showValidation(name) && (
                <div
                  className="form-error"
                  style={{
                    top: error === 'top' ? -5 : 'calc(100% - 5px)',
                  }}>
                  {errors[name]}
                </div>
              )}
            </div>
          )
      )}
    </div>
  );
};
