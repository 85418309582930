import { useState, useEffect, useRef } from 'react';
import { Modal } from '@maker-ui/elements';

import { GearIcon } from '@/components/shared/Icons';
import { PartnerResponse } from '../../../pages/api/get-partner';
import { useOrder } from '@/context';
import { cn } from '@/utils/helper';
import styles from './PartnerLogin.module.scss';

/**
 * The `AdminCheckout` component lets Hempitecture admins enter a password to bypass the
 * payment process. This is helpful for alternative payment methods like direct invoices.
 */
export const PartnerLogin = () => {
  const { order, setPartner } = useOrder();
  const [show, set] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const buttonRef = useRef(null);

  useEffect(() => {
    setUsername('');
    setPassword('');
    setError('');
  }, [show]);

  async function submitHandler(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    if (!username || !password) {
      setError('Please complete both fields');
      return;
    }
    try {
      const res: PartnerResponse = await fetch('/api/get-partner', {
        method: 'POST',
        body: JSON.stringify({ code: password, username }),
      }).then((res) => res.json());

      if (res.success) {
        setError('');
        setPartner(res.partner);
        set(false);
      }

      if (res.error) {
        setError(
          'Incorrect username or access code. Please check with your Hempitecture representative.'
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  function removeCode() {
    setPartner(undefined);
    set(false);
  }

  return (
    <>
      <button
        onClick={() => set(!show)}
        ref={buttonRef}
        className={styles.button}>
        <GearIcon />
      </button>
      <Modal
        show={show}
        set={set}
        background="rgba(0,0,0, 0.71)"
        center
        closeOnBlur>
        <div className={styles.form}>
          <h2>Partner Checkout</h2>
          {!order.partner ? (
            <div className="flex flex-col">
              <p className="text-center">
                Please enter your organization username and access code.
              </p>
              <form>
                <label className="bold caps">
                  Username
                  <input
                    required
                    placeholder="Your organization username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </label>
                <label className="bold caps">
                  Access Code
                  <input
                    required
                    placeholder="Your access code"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>

                <button
                  disabled={!username || !password}
                  className={cn(styles.submit, 'btn')}
                  onClick={submitHandler}>
                  Authenticate
                </button>
              </form>
              <div className={styles.error}>{error}</div>
            </div>
          ) : (
            <div className={cn(styles.confirm, 'flex flex-col align-center')}>
              <div className={styles.success}>Success</div>
              <div className={styles.notice}>
                Welcome, <strong>{order.partner?.name}</strong>! You are now
                able to complete your order as a preferred partner with custom
                payment terms.
              </div>
              <button
                className={cn(styles.btn_continue, 'btn')}
                onClick={() => set(false)}>
                Continue
              </button>
              <button
                className={cn(styles.btn_remove, 'btn')}
                onClick={removeCode}>
                Remove Partner Code
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
