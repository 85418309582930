import { useEffect, useState } from 'react';
import { Spinner } from '@maker-ui/loaders';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import type { FormValues } from '@hempitecture/types';

import { useOrder, useShipment, useError } from '@/context';
import { cn } from '@/utils/helper';
import styles from './PaymentOptions.module.scss';

/**
 * Card styles for Stripe iframe
 */
export const cardStyle = {
  style: {
    base: {
      iconColor: '#00',
      color: '#000',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#000',
      },
      '::placeholder': {
        color: '#a7a7a7',
      },
    },
    invalid: {
      iconColor: '#eb1c26',
      color: '#eb1c26',
    },
  },
};

/**
 * The `Stripe` component handles all logic related to credit card
 * payment with Stripe.
 *
 * @param nextStep - control of the order form stepper
 */
export const Stripe = ({ nextStep }: { nextStep: () => void }) => {
  const { values }: { values: FormValues } = useFormikContext();
  const [paid, setPaid] = useState(false);
  const { order, paymentType, setStripeDetails, createOrder, setIsProcessing } =
    useOrder();
  const { shipping, orderShipment, freeShipping } = useShipment();
  const { setError } = useError();

  // Stripe Elements state & context
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = useState(false);
  const [localError, setLocalError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

  async function fetchClientSecret() {
    try {
      const stripeResponse = await fetch(
        '/api/payments/create-payment-intent',
        {
          method: 'POST',
          body: JSON.stringify({
            items: values.products,
            shipRate:
              order.fulfillmentType === 'Delivery'
                ? shipping.shippingRate
                : undefined,
            discount: order?.partner?.discount,
            freeShipping,
            taxRate:
              order.fulfillmentType === 'Delivery'
                ? order.taxRates[values.state]
                : order.taxRates[order.selectedPickupLocation.stateCode],
          }),
        }
      ).then((res) => {
        if (res.status === 500) {
          setError('CREDIT_CARD');
        }
        return res.json();
      });
      setClientSecret(stripeResponse.clientSecret);
    } catch (err) {
      // todo handle error
      console.error(err);
    }
  }

  useEffect(() => {
    fetchClientSecret();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order.orderComplete) {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderComplete]);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setLocalError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setIsProcessing(true);
    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      setPaid(true);
      if (payload.error) {
        setLocalError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setLocalError(null);
        setProcessing(false);
        setSucceeded(true);

        // If shipping, book load
        if (order.fulfillmentType === 'Delivery') {
          await orderShipment({
            stripeId: payload.paymentIntent.id,
            stripeTotal: payload.paymentIntent.amount / 100,
            orderType: 'Credit Card',
          });
        } else {
          // For pickup orders
          createOrder({
            stripeTotal: payload.paymentIntent.amount / 100,
            stripeId: payload.paymentIntent.id,
            pickupTaxRate:
              order.taxRates[order.selectedPickupLocation.stateCode],
            orderNumber: Math.floor(100000 + Math.random() * 900000),
            orderType: 'Credit Card',
          });
        }

        setStripeDetails(
          payload.paymentIntent.id,
          payload.paymentIntent.amount / 100
        );
      }
    } catch (err) {
      //Todo handle error
      console.error(err);
    }
  };

  return (
    <div
      className={cn(
        styles.pay_wrapper,
        'flex align-center justify-center flex-col'
      )}>
      <span>A 3% transaction fee is applied to credit card payments.</span>
      <img src="/credit-card-logos.png" alt="accepted credit card logos" />
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
        <button
          id="submit"
          data-cy="submit-stripe-payment"
          className={styles.btn_submit}
          disabled={
            paid ||
            processing ||
            disabled ||
            succeeded ||
            paymentType !== 'Credit Card'
          }>
          <span id="button-text">
            {processing && !order.orderComplete ? (
              <div
                className={cn(
                  styles.pending,
                  'flex align-center justify-center'
                )}>
                <Spinner
                  css={{ height: 20, width: 20, marginRight: 15 }}
                  colors={{
                    primary: '#fff',
                  }}
                />
                Processing...
              </div>
            ) : !processing && order.orderComplete ? null : (
              'Submit Order'
            )}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {localError && (
          <div className={styles.stripe_error} role="alert">
            {localError}
          </div>
        )}
      </form>
    </div>
  );
};
