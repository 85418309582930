import { cn } from '@/utils/helper';
import styles from './Footer.module.scss';

const links = [
  { label: 'www.hempitecture.com', url: 'https://www.hempitecture.com/' },
  {
    label: 'Frequently Asked Questions',
    url: 'https://www.hempitecture.com/resources/#FAQ',
  },
  {
    label: 'Product Spec Sheet',
    url: 'https://www.hempitecture.com/resources/',
  },
  {
    label: 'Order & Shipping Policy',
    url: 'https://www.hempitecture.com/resources/',
  },
  {
    label: 'Contact',
    url: 'mailto:sales@hempitecture.com?subject=Buy%20Direct%20Support',
  },
];

export const Footer = () => {
  return (
    <div id="footer" className={cn(styles.root, 'flex flex-col')}>
      <div className={styles.support}>
        Need additional help? Give us a call during MST business hours at{' '}
        <a href="tel:+12082188698">(208) 218-8698</a>. Let us earn your
        business.
      </div>
      <ul className="flex align-center justify-center width-100">
        {links.map(({ label, url }, index) => (
          <li key={index}>
            <a href={url} target="_blank" rel="noreferrer">
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
