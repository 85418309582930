import { useState, useEffect } from 'react';
import { Modal } from '@maker-ui/elements';
import { useFormikContext, Field, FieldArray } from 'formik';
import type { Inventory } from '@hempitecture/types';

import { FormStepProps } from '../schema';
import { StepHeader } from '../StepHeader/StepHeader';
import {
  BackIcon,
  BlockIcon,
  DownIcon,
  MixIcon,
  PlusIcon,
  TrashIcon,
} from '@/components/shared/Icons';
import { useOrder, useShipment } from '@/context';
import { useQuoteFetcher } from '@/hooks';
import { PalletPricing } from '@/components/layout/PalletPricing';
import { cn } from '@/utils/helper';
import styles from './ProductForm.module.scss';

interface SKUObject {
  name: string;
  sku: string;
}

/**
 * The `ProductForm` component is Step 2 of the order form and is responsible
 * for saving products to the cart.
 *
 * @param nextStep - control of the order form stepper
 */
export const ProductForm = ({ nextStep }: FormStepProps) => {
  const [maxError, setMaxError] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const { order, setHempcreteType, setPalletCount } = useOrder();
  const { shipping } = useShipment();
  const { resetQuote } = useQuoteFetcher();
  const isWool = order.productType === 'hempwool';

  const { errors, values, setFieldValue }: any = useFormikContext();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    let total = 0;
    values.products.forEach(({ pallets }) => {
      if (pallets) {
        total += pallets;
      }
    });

    setPalletCount(total);

    // Reset the shipping quotes if user changes the order quantity
    if (shipping.fetchState.count > 0 && total !== order.palletCount) {
      resetQuote(true);
    }

    if (total > 26) {
      setMaxError(true);
    }

    if (maxError && total < 26) {
      setMaxError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products]);

  function productCheck() {
    let i = 0;
    let productError = false;
    if (values.products.length > 0) {
      do {
        if (values.products[i].sku === 0 || !values.products[i].sqft) {
          productError = true;
        }
        i++;
      } while (i < values.products.length);
      return !productError ? true : false;
    }

    return false;
  }

  const validated =
    !maxError && !errors.products?.length && productCheck() ? true : false;

  function getPalletCount(index: number) {
    const val = values.products[index];
    return {
      count: Math.ceil(val.sqft / order.inventory[val.sku]?.palletSize),
      sqft: order.inventory[val.sku]?.palletSize,
    };
  }

  // Create an alphanumerically sorted array to render product select options
  const currentType = isWool
    ? 'HempWool'
    : order.hempcreteType === 'block'
    ? 'Blocks'
    : 'Hempcrete';
  const skus = Object.keys(order.inventory)
    .map((sku) => ({
      sku,
      name: order.inventory[sku].name,
    }))
    ?.filter(({ sku }) => order.inventory[sku].productType === currentType)
    ?.sort((a: SKUObject, b: SKUObject) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA.includes('PLANTPAD') && !nameB.includes('PLANTPAD')) {
        return 1;
      } else if (!nameA.includes('PLANTPAD') && nameB.includes('PLANTPAD')) {
        return -1;
      } else {
        return nameA.localeCompare(nameB, 'en', { numeric: true });
      }
    });

  function renderForm() {
    return (
      <>
        <div
          className={cn(styles.category, 'flex align-center justify-between')}>
          <h5>
            {isWool
              ? 'HempWool®'
              : order.hempcreteType === 'block'
              ? 'Hempcrete Building Blocks'
              : 'Cast in Place Hemp and Lime'}
          </h5>
          {isWool || (!isWool && order.hempcreteType) ? (
            <button
              className={styles.btn_pricing}
              type="button"
              onClick={() => setShowPricing(true)}>
              View Pallet Pricing
            </button>
          ) : null}
        </div>
        <Modal show={showPricing} set={setShowPricing} closeOnBlur>
          <PalletPricing
            type={currentType}
            close={() => setShowPricing(false)}
            inventory={order.inventory as Inventory}
          />
        </Modal>
        <div className="flex">
          <div className={cn(styles.col, styles.col_left)}>Product</div>
          <div className={cn(styles.col, styles.col_right)}>
            {order.hempcreteType === 'cast' ? 'cubic feet' : 'sqft'}
          </div>
        </div>
        <FieldArray
          name="products"
          render={({ remove, push }) => (
            <div>
              {values.products.length > 0
                ? values.products.map((p, index) => {
                    const pallets = productCheck()
                      ? getPalletCount(index).count
                      : 0;
                    const squareFootage =
                      pallets !== 0 && getPalletCount(index).sqft;

                    return (
                      <div key={index} className={`product-${index}`}>
                        <div className="flex row">
                          <div className="flex-1">
                            <div
                              className="custom-select"
                              style={{ marginRight: 10 }}>
                              <Field
                                as="select"
                                name={`products[${index}].sku`}
                                data-cy={`product-select-${index}`}>
                                <option>Select a product</option>
                                {skus.map(({ sku, name }) => (
                                  <option key={sku} value={sku}>
                                    {name}
                                  </option>
                                ))}
                              </Field>
                              <DownIcon className="down-caret" />
                              <div style={{ display: 'none' }}>
                                <Field
                                  name={`products[${index}].pallets`}
                                  type="number"
                                  value={
                                    (values.products[index].pallets =
                                      pallets || 0)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div
                                className={cn(
                                  styles.sqft_wrapper,
                                  order.hempcreteType === 'cast'
                                    ? styles.cubic
                                    : ''
                                )}>
                                <Field
                                  className={styles.sqft}
                                  data-cy={`product-quantity-${index}`}
                                  name={`products[${index}].sqft`}
                                  placeholder="24"
                                  type="number"
                                  min="0"
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className={styles.btn_remove}
                            data-cy={`product-remove-${index}`}
                            onClick={() => remove(index)}>
                            <TrashIcon />
                          </button>
                        </div>
                        <div
                          data-cy={`pallet-counter-${index}`}
                          className={cn(
                            styles.pallets,
                            maxError ? styles.max_error : null,
                            'flex justify-end'
                          )}>
                          {pallets > 0
                            ? `${pallets} pallet${pallets > 1 ? 's' : ''} - ${(
                                squareFootage * pallets
                              ).toLocaleString()} ${
                                order.hempcreteType === 'cast' ? 'ft³' : 'ft²'
                              }`
                            : null}
                        </div>
                      </div>
                    );
                  })
                : null}
              <div className="flex justify-start">
                <button
                  type="button"
                  data-cy="add-product"
                  className={cn(styles.btn_add, 'btn-add flex align-center')}
                  onClick={() => push({ sku: 0, sqft: '' })}>
                  <PlusIcon /> Add
                </button>
              </div>
            </div>
          )}
        />
        <div className="flex-1" />
        {maxError ? (
          <div className={styles.shipping_error}>
            Our Full Truckload shipments currently support a maximum of{' '}
            <strong>26 pallets</strong>. If you need more, please contact us
            directly or use this service to place a second order.
          </div>
        ) : null}
        <button
          type="button"
          data-cy="submit-products"
          disabled={!validated}
          className={cn('btn-next btn', validated ? 'active' : null)}
          onClick={async () => {
            if (validated && nextStep) {
              nextStep('Updated Cart');
            }
          }}>
          Continue to Shipping
        </button>
      </>
    );
  }

  return (
    <div
      className={cn(styles.root, 'product-form flex flex-col')}
      data-cy="product-form">
      <StepHeader step={1}>Select Your Products</StepHeader>
      {!isWool && order.hempcreteType && (
        <button
          type="button"
          className={cn(styles.btn_back, 'flex align-center')}
          onClick={() => setHempcreteType(null)}>
          <BackIcon /> Back
        </button>
      )}
      {!isWool && order.hempcreteType === 'cast' && (
        <div className={styles.instructions}>
          To calculate cubic feet, multiply your wall square footage by the
          depth of your wall (in feet).
        </div>
      )}
      {isWool || (!isWool && order.hempcreteType)
        ? renderForm()
        : !isWool &&
          !order.hempcreteType && (
            <div className={cn(styles.hempcrete_types, 'flex flex-col')}>
              {hempcreteOptions.map(({ title, subtitle, icon, value }) => (
                <button
                  key={value}
                  type="button"
                  onClick={() => {
                    if (value !== order.hempcreteType) {
                      // Reset Formik product values
                      setFieldValue('products', [
                        {
                          sku: 0,
                          sqft: 0,
                          pallets: 0,
                        },
                      ]);
                    }
                    setHempcreteType(value);
                  }}>
                  {icon}
                  <div className={styles.title}>{title}</div>
                  <div className={styles.subtitle}>{subtitle}</div>
                </button>
              ))}
            </div>
          )}
    </div>
  );
};

interface Option {
  title: string;
  subtitle: string;
  value: 'cast' | 'block';
  icon: React.ReactElement;
}

const hempcreteOptions: Option[] = [
  {
    title: 'Cast in Place',
    subtitle: 'Mix your own Hempcrete',
    value: 'cast',
    icon: <MixIcon />,
  },
  {
    title: 'Block',
    subtitle: 'Purchase pre-made blocks',
    value: 'block',
    icon: <BlockIcon />,
  },
];
