import styles from './StepHeader.module.scss';

interface StepHeaderProps {
  step: number;
  children: string;
}

/**
 * The `StepHeader` component shows the user the current step of the
 * order form in plain text.
 */
export const StepHeader = ({ step, children }: StepHeaderProps) => (
  <div className={styles.heading}>
    <div className={styles.number}>Step {step}</div>
    <h4>{children}</h4>
  </div>
);
